body {
  background: linear-gradient(0deg, #900 0%, #009 100%);
  margin: 0;
  padding: 0;
  font-family: monospace;
  line-height: 1.25;
}

html {
  background: #000;
}

h1 {
  margin: 0;
  padding: 8px;
  font-size: 32px;
}

p, h2 {
  margin: 0;
  padding: 8px;
  font-size: 16px;
}

p {
  color: #fff9;
}

a, h2, h1 {
  color: #fff;
}

canvas {
  image-rendering: pixelated;
  cursor: url("cursor.5c7ea5b6.png"), auto;
  width: 100%;
  background: #000;
  display: block;
  box-shadow: 0 0 32px #ffffff1a;
}

.container {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 8px 0;
}

.button {
  width: 20%;
  color: #fff;
  text-align: center;
  background: linear-gradient(0deg, #00c 0%, #606 70%, #c00 100%);
  border-radius: 16px;
  margin: 0 auto;
  padding: 16px;
  text-decoration: none;
  display: block;
  box-shadow: 0 0 16px #fff3;
}

/*# sourceMappingURL=index.a93a912e.css.map */
