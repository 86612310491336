body {
	background: linear-gradient(0deg, #990000 0%, #000099 100%);
	font-family: monospace;
	line-height: 1.25;
	padding: 0;
	margin: 0;
}

html {
	background: #000000;
}

h1 {
	font-size: 32px;
	padding: 8px;
	margin: 0;
}

p, h2 {
	font-size: 16px;
	padding: 8px;
	margin: 0;
}

p {
	color: rgba(255, 255, 255, 0.6);
}

a, h2, h1 {
	color: #ffffff;
}

canvas {
	image-rendering: pixelated;
	cursor: url('../packages/editor/src/view/textures/cursor.png'), auto;
	background: #000000;
	box-shadow: 0 0 32px rgba(255, 255, 255, 0.1);
	width: 100%;
	display: block;
}

.container {
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
	padding: 8px 0;
}

.button {
	margin: 0 auto;
	width: 20%;
	padding: 16px;
	background: linear-gradient(0deg, #0000cc 0%, #660066 70%, #cc0000 100%);
	color: #ffffff;
	display: block;
	text-align: center;
	text-decoration: none;
	border-radius: 16px;
	box-shadow: 0 0 16px rgba(255, 255, 255, 0.2);
}